<template>
  <v-container class="rn-section-gap">
    <v-row align="start" class="row--35">
      <v-col lg="12" md="12" sm="12" cols="12" order="2" order-md="1">
        <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
          <h2 class="heading-title">Contact me</h2>
          <p class="description">
            I'll be happy to hear about your project idea!
          </p>
        </div>
        <div class="form-wrapper">
          <form @submit.prevent="submit">
            <label>
              <div
                class="invalid-feedback"
                v-show="submitted && !$v.name.required"
              >
                Name is required.
              </div>
              <div
                v-show="submitted && !$v.name.minLength"
                class="invalid-feedback"
              >
                Name must be at least 3 characters.
              </div>
              <input
                type="text"
                v-model="name"
                placeholder="Your Name *"
                :class="{
                  'is-invalid': submitted && $v.name.$error,
                }"
              />
            </label>

            <label>
              <div
                class="invalid-feedback"
                v-show="submitted && !$v.email.required"
              >
                Email is required.
              </div>
              <div
                v-show="submitted && !$v.email.email"
                class="invalid-feedback"
              >
                The email is not valid.
              </div>
              <input
                type="text"
                v-model="email"
                placeholder="Your email *"
                :class="{
                  'is-invalid': submitted && $v.email.$error,
                }"
              />
            </label>

            <label>
              <input type="text" v-model="company" placeholder="Company name" />
            </label>
            <label>
              <div
                class="invalid-feedback"
                v-show="submitted && !$v.message.required"
              >
                Message is required.
              </div>
              <textarea
                v-model="message"
                placeholder="How can I help you? *"
                :class="{
                  'is-invalid': submitted && $v.email.$error,
                }"
              ></textarea>
            </label>
            <button
              class="rn-button-style--2 btn_solid"
              type="submit"
              value="submit"
            >
              Send
            </button>
          </form>
        </div>
      </v-col>
      <!--       <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
        <div class="thumbnail mb_md--40 mb_sm--40">
          <img src="../../assets/images/about/about-6.jpg" alt="trydo" />
        </div>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      name: "",
      email: "",
      company: "",
      message: "",
      submitted: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    email: {
      required,
      email,
    },
    message: {
      required,
    },
  },
  methods: {
    clearForm() {
      this.name = "";
      this.email = "";
      this.company = "";
      this.message = "";
    },
    async submit() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        const response = await this.$store.dispatch("users/sendEmail", {
          name: this.name,
          email: this.email,
          company: this.company,
          message: this.message,
        });
        if (response.status === 200) {
          this.submitted = false;
          this.clearForm();
          Swal.fire({
            position: "center",
            title: "Success!",
            confirmButtonColor: "#F5344F",
            text: "Your message has been sent!",
            icon: "success",
            timer: 5000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            timer: 3000,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
input,
textarea {
  color: rgba(198, 201, 216, 0.75);
  border: 2px solid rgba(255, 255, 255, 0.2);
  display: block;
  width: 100%;
  padding: 0 20px;
  border-radius: 5px;
  transition: all 0.3s ease;

  line-height: 46px;
  margin-bottom: 20px;
  outline: none;
  font-size: 15px;
  letter-spacing: 0.1px;
}

input {
  height: 50px;
}

textarea {
  height: 120px;
}
.is-invalid {
  border-color: red;
}
.invalid-feedback {
  display: block;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
