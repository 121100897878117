<template>
  <div class="active-dark" id="home">
    <!-- Start Slider Area -->
    <div class="slider-wrapper">
      <SliderOne></SliderOne>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area -->

    <About id="about" />

    <!-- End About Area -->

    <!-- Start Portfolio Area -->
    <!--     <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <div class="portfolio-sacousel-inner pb--30">
        <div class="container">
          <div class="row">
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 class="heading-title">All Works</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available,
                  <br />
                  but the majority have suffered alteration.
                </p>
              </div>
            </v-col>
          </div>
        </div>
        <Portfolio />
      </div>
    </div> -->
    <!-- End Portfolio Area -->
    <Contact id="contact" />
  </div>
</template>

<script>
import Header from "../components/header/Header";
import SliderOne from "../components/slider/SliderOne";
import About from "../components/about/About";
import ServiceTwo from "../components/service/ServiceTwo";
import Portfolio from "../components/portfolio/Portfolio";
import CounterOne from "../components/counter/CounterOne";
import Testimonial from "../components/testimonial/Testimonial";
import Blog from "../components/blog/Blog";
import Brand from "../components/brand/Brand";
import Footer from "../components/footer/Footer";
import Contact from "../components/contact/Contact";

export default {
  components: {
    SliderOne,
    Header,
    About,
    ServiceTwo,
    Portfolio,
    CounterOne,
    Testimonial,
    Blog,
    Brand,
    Footer,
    Contact,
  },
  data() {
    return {};
  },
};
</script>
