<template>
  <div>
    <Header />
    <router-view />
    <Footer />
    <Whatsapp />
    <VFabTransition />
    <Snackbar />
    <LoadingOverlay />
  </div>
</template>

<script>
import Header from "../components/header/HeaderOnePage";
import Footer from "../components/footer/FooterTwo";
import Whatsapp from "@/components/Whatsapp";
import VFabTransition from "@/components/VFabTransition";
import Snackbar from "@/components/Snackbar";
import LoadingOverlay from "@/components/LoadingOverlay";

export default {
  name: "LayoutView",
  components: {
    Header,
    Footer,
    Whatsapp,
    VFabTransition,
    Snackbar,
    LoadingOverlay,
  },
  async created() {
    await this.$store.dispatch("settings/getSettings", null, { root: true });
    await this.$store.dispatch("menus/getMenus", null, {
      root: true,
    });
    await this.$store.dispatch("services/getServices", null, {
      root: true,
    });
    await this.$store.dispatch("portfolios/getPortfolios", null, {
      root: true,
    });
    await this.$store.dispatch("reviews/getReviews", null, {
      root: true,
    });
    await this.$store.dispatch("calltoactions/getCallToActions", null, {
      root: true,
    });
    await this.$store.dispatch("logos/getLogos", null, {
      root: true,
    });
  },
};
</script>
