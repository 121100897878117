<template>
  <v-row>
    <!-- Start Single Service  -->
    <v-col
      xl="4"
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--3 text-white">
        <div class="icon">
          <img :src="item.src" alt="Creative Agency" />
        </div>
        <div class="content">
          <h3 class="heading-title">
            {{ item.title }}
          </h3>
          <p>
            {{ item.desc }}
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        serviceContent: [
          {
            src: require("../../assets/images/icons/internet.png"),
            title: "Website Development",
            desc: `Show your business on internet with the best, more stable and fastest technologies.`,
          },
          {
            src: require("../../assets/images/icons/shopping-cart.png"),
            title: " Ecommerce Development",
            desc: `Turn your business idea into reality, sell what you want on the internet.`,
          },
          {
            src: require("../../assets/images/icons/ethereum.png"),
            title: "Dapps Development",
            desc: `Be decentralized, move your business to the new Web 3.0.`,
          },
        ],
      };
    },
  };
</script>
