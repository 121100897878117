import Vue from "vue";
import Vuex from "vuex";
import Repository from "../repositories/Repository";

Vue.use(Vuex);

const users = {
  namespaced: true,
  actions: {
    async sendEmail({ commit }, payload) {
      const response = await Repository.post(`/users/sendEmail`, payload);
      return response;
    },
  },
};

const sliders = {
  namespaced: true,
  state: {
    sliders: [
      {
        title: "",
        subtitle: "",
        sliderImg: {
          public_id: "",
          url: "",
        },
        buttonText: "",
        buttonURL: "",
      },
    ],
  },
  mutations: {
    setSliders(state, payload) {
      state.sliders = payload;
    },
  },
  actions: {
    async getSliders({ commit }, payload) {
      const response = await Repository.get(`/sliders`, {
        headers: { token: this.state.token },
      });
      commit("setSliders", response.data);
    },
  },
  getters: {
    sliders: (state) => {
      return state.sliders;
    },
  },
};

const settings = {
  namespaced: true,
  state: {
    settings: [
      {
        videobackground: {
          public_id: "",
          url: "",
        },
        logoURL: {
          imageURL: "",
        },
      },
    ],
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload;
    },
  },
  actions: {
    async getSettings({ commit }, payload) {
      const response = await Repository.get(`/settings`, {
        headers: { token: this.state.token },
      });
      commit("setSettings", response.data);
    },
  },
  getters: {
    settings: (state) => {
      return state.settings;
    },
  },
};

const calltoactions = {
  namespaced: true,
  state: { calltoactions: [] },
  mutations: {
    setCallToActions(state, payload) {
      state.calltoactions = payload;
    },
  },
  actions: {
    async getCallToActions({ commit }, payload) {
      const response = await Repository.get(`/calltoactions`, {
        headers: { token: this.state.token },
      });
      commit("setCallToActions", response.data);
    },
  },
  getters: {
    calltoactions: (state) => {
      return state.calltoactions;
    },
  },
};

const menus = {
  namespaced: true,
  state: { menus: [] },
  mutations: {
    setMenus(state, payload) {
      state.menus = payload;
    },
  },
  actions: {
    async getMenus({ commit }, payload) {
      const response = await Repository.get(`/menus`, {
        headers: { token: this.state.token },
      });
      commit("setMenus", response.data);
    },
  },
  getters: {
    menus: (state) => {
      return state.menus;
    },
  },
};

const services = {
  namespaced: true,
  state: { services: [] },
  mutations: {
    setServices(state, payload) {
      state.services = payload;
    },
  },
  actions: {
    async getServices({ commit }, payload) {
      const response = await Repository.get(`/services`, {
        headers: { token: this.state.token },
      });
      commit("setServices", response.data);
    },
  },
  getters: {
    services: (state) => {
      return state.services;
    },
  },
};

const logos = {
  namespaced: true,
  state: { logos: [] },
  mutations: {
    setLogos(state, payload) {
      state.logos = payload;
    },
  },
  actions: {
    async getLogos({ commit }, payload) {
      const response = await Repository.get(`/logos`, {
        headers: { token: this.state.token },
      });
      commit("setLogos", response.data);
    },
  },
  getters: {
    logos: (state) => {
      return state.logos;
    },
  },
};

const portfolios = {
  namespaced: true,
  state: { portfolios: [] },
  mutations: {
    setPortfolios(state, payload) {
      state.portfolios = payload;
    },
  },
  actions: {
    async getPortfolios({ commit }, payload) {
      const response = await Repository.get(`/portfolios/listActives`, {
        headers: { token: this.state.token },
      });
      commit("setPortfolios", response.data);
    },
  },
  getters: {
    portfolios: (state) => {
      return state.portfolios;
    },
  },
};

const reviews = {
  namespaced: true,
  state: { reviews: [] },
  mutations: {
    setReviews(state, payload) {
      state.reviews = payload;
    },
  },
  actions: {
    async getReviews({ commit }, payload) {
      const response = await Repository.get(`/reviews`, {
        headers: { token: this.state.token },
      });
      commit("setReviews", response.data);
    },
  },
  getters: {
    reviews: (state) => {
      return state.reviews;
    },
  },
};

export default new Vuex.Store({
  state: {
    snackbar: { showing: false, text: "", color: "" },
    loadingOverlay: false,
  },
  mutations: {
    setSnackbar(state, snackbar) {
      state.snackbar = snackbar;
    },
    setLoadingOverlay(state, loadingOverlay) {
      state.loadingOverlay = loadingOverlay;
    },
    removeLoadingOverlay(state, loadingOverlay) {
      state.loadingOverlay = loadingOverlay;
    },
  },
  actions: {
    setSnackbar({ commit }, snackbar) {
      snackbar.showing = true;
      snackbar.color = snackbar.color || "success";
      commit("setSnackbar", snackbar);
    },
    setLoadingOverlay({ commit }, loadingOverlay) {
      loadingOverlay = true;
      commit("setLoadingOverlay", loadingOverlay);
    },
    removeLoadingOverlay({ commit }, loadingOverlay) {
      loadingOverlay = false;
      commit("removeLoadingOverlay", loadingOverlay);
    },
  },
  modules: {
    menus: menus,
    calltoactions: calltoactions,
    settings: settings,
    sliders: sliders,
    logos: logos,
    services: services,
    portfolios: portfolios,
    reviews: reviews,
    users: users,
  },
});
